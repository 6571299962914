.chart-section-button {
  @apply px-6 py-4 rounded-xl inline-flex items-center hover:opacity-70 font-medium text-base
}

.token-table-heading {
  @apply text-sm text-gray-400 font-medium uppercase tracking-[0.12em];
}

.token-table-data {
  @apply py-4;
}

.main-chart-shadow {
  @apply shadow-[4px_12px_80px_0_rgba(0,0,0,0.24)];
}

