.wrapper {
  @apply md:flex justify-center xl:justify-between items-center md:space-x-8 px-8 py-[120px] md:py-[50px] max-w-5xl mx-auto;
}

.content {
  @apply text-center md:text-left md:mr-16;
}

.summary {
  @apply text-teal-400 text-sm font-semibold;
}

.title {
  @apply text-white text-[28px] mt-4 md:text-5xl font-bold !leading-snug;
}

.description {
  @apply text-gray-400 mt-8 max-w-md md:max-w-xl mx-auto;
}

.section-image {
  @apply max-w-md xl:max-w-lg mx-auto pt-20 md:py-20
}