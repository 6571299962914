.stat-container {
  @apply px-4 py-9 bg-gray-900 rounded-lg flex flex-col justify-center;
}

.stat-title {
  @apply font-bold text-4xl text-white;
}

.stat-description {
  @apply text-gray-400 mt-1;
}